import {Box, Heading} from '@primer/components'
import '@primer/css/layout/index.scss'
import React from 'react'
import Header from './Header'
import Head from './Head'

// FIXME: this works around known issues with Heading's default prop {m: 0}
Object.assign(Heading.defaultProps, {
  m: null,
  mt: 0,
  mb: 0
})

export default function Layout({pageContext, isHeroPage, children}) {
  return (
    <>
      <Head title={pageContext.frontmatter.title} description={pageContext.frontmatter.description} />
      <Header isHeroPage={isHeroPage} />
      <Box bg="black" color="blue.2">
        {children}
      </Box>
    </>
  )
}

Layout.defaultProps = {
  pageContext: {
    frontmatter: {}
  },
  isHeroPage: true
}
