import {Flex, Link, Sticky} from '@primer/components'
import React from 'react'

function Header({isHeroPage}) {
  return (
    <Sticky display={isHeroPage ? [null, 'none'] : null} className="no-print">
      <Flex py={3} px={[3, null, null, 4]} alignItems="center" justifyContent="space-between" bg="black">
        <Flex alignItems="center" width={1} justifyContent="center">
          <Link href="/" color="blue.4" fontSize={'1.25rem'} fontFamily="Oswald">
            Andrew Boyarshin
          </Link>
        </Flex>
      </Flex>
    </Sticky>
  )
}

export default Header
